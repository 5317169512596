var A24, Cookie, SerializeObject;

Cookie = require('cookies-js');

A24 = require('data/constants');

SerializeObject = require('util/serialize-object');

module.exports = function() {
  var $header, dismiss_banner;
  $header = $('header');
  dismiss_banner = function(time) {
    return Cookie.set('newsletter-signup-dismissed', true, {
      expires: time
    });
  };
  $('form.newsletter-signup').on('submit', function(e) {
    var $form, messages, payload;
    $form = $(this);
    payload = SerializeObject($form);
    messages = $form.find('.messages');
    e.preventDefault();
    return $.ajax({
      method: 'post',
      url: $form.attr('action'),
      data: payload,
      beforeSend: function() {
        return messages.removeClass('error');
      },
      success: function(res) {
        if (res.success != null) {
          $form.addClass('success');
          messages.addClass('success');
          dismiss_banner(A24.FUTURE);
          $(document.body).trigger('trackedAction', {
            trackedAction: 'newsletter_subscribe',
            trackedActionParams: payload.signupLocation
          });
        } else {
          messages.addClass('error');
        }
        return messages.show();
      }
    });
  });
  return $('aside.newsletter-banner').each(function(index, el) {
    var banner, close;
    banner = $(el);
    close = banner.find('button.close-newsletter');
    if (!Cookie('visited')) {
      Cookie.set('visited', true, {
        expires: A24.ONE_WEEK
      });
      banner.addClass('active');
    }
    return close.on('click', function(e) {
      banner.removeClass('active');
      if (!Cookie.get('newsletter-signup-dismissed')) {
        return dismiss_banner(A24.FUTURE);
      }
    });
  });
};
