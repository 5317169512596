var action, i, len, playbook, queue, ref;

queue = document.body.className.split(' ');

playbook = {
  'before': require('init/before'),
  'common': require('init/common'),
  'after': require('init/after'),
  'about': require('init/about'),
  'article': require('init/article'),
  'blog': require('init/story'),
  'checkout': require('init/shop/checkout'),
  'checkout-address': require('init/shop/checkout-address'),
  'checkout-payment': require('init/shop/checkout-payment'),
  'contact': require('init/contact'),
  'error': require('init/error'),
  'home': require('init/home'),
  'film': require('init/film'),
  'films': require('init/films'),
  'job': require('init/job'),
  'notes': require('init/notes'),
  'pages': require('init/pages'),
  'position': require('init/position'),
  'product-type-download': require('init/shop/product-types/download'),
  'shop': require('init/shop/shop'),
  'show': require('init/film'),
  'staff': require('init/staff'),
  'story': require('init/story'),
  'television': require('init/television'),
  'zine': require('init/zine')
};

playbook.before();

playbook.common();

for (i = 0, len = queue.length; i < len; i++) {
  action = queue[i];
  if ((ref = playbook[action]) != null) {
    ref.call();
  }
}

playbook.after();
