var $, cookie_banner, declareDataPresence, footer_position, lazy_images, logo, mobile_navigation, newsletter, search, shop_ui, sticky_navigation, tweets, video_previews;

$ = require('jquery');

lazy_images = require('init/tasks/lazy-images');

logo = require('init/tasks/logo');

newsletter = require('init/tasks/newsletter');

cookie_banner = require('init/tasks/cookie-banner');

search = require('init/tasks/search');

mobile_navigation = require('init/tasks/mobile-navigation');

sticky_navigation = require('init/tasks/sticky-navigation');

footer_position = require('init/tasks/footer-position');

video_previews = require('init/tasks/video-previews');

tweets = require('init/tasks/tweets');

shop_ui = require('init/tasks/shop-ui');

declareDataPresence = function(e) {
  var $input;
  $input = $(this);
  if ($input.val()) {
    return $input.parents('label').addClass('with-data');
  } else {
    return $input.parents('label').removeClass('with-data');
  }
};

module.exports = function() {
  lazy_images('.responsive-image');
  newsletter();
  cookie_banner();
  search();
  mobile_navigation();
  sticky_navigation();
  footer_position();
  video_previews('.video-preview');
  shop_ui();
  tweets();
  $('.tweet-text a').each(function(i, el) {
    var $link;
    $link = $(el);
    if ($link.text().indexOf('#') === 0) {
      return $link.addClass('is-hashtag');
    }
  });
  return $('form :input').each(function(index, input) {
    var $input, $label;
    $input = $(input);
    $input.on('input', declareDataPresence);
    if ($input.val()) {
      $input.trigger('input');
    }
    if ($input.is('[type="file"]')) {
      $label = $input.parents('label');
      $input.on('change', function(e) {
        var file, filename;
        if ($input.val()) {
          file = $input.get(0).files[0];
          if (file.size > 10 * 1024 * 1024) {
            $input.val('');
            $input.trigger('change');
            return alert('The maximum allowed file size is 10MB.');
          } else {
            $label.addClass('with-file');
            filename = file.name;
            return $label.find('.filename').text(filename);
          }
        } else {
          $label.find('.filename').text('');
          return $label.removeClass('with-file');
        }
      });
      $label.find('.file-control-add').on('click', function(e) {
        e.preventDefault();
        return $input.trigger('click');
      });
      return $label.find('.file-control-remove').on('click', function(e) {
        e.preventDefault();
        $input.val('');
        $input.trigger('change');
        return $label.removeClass('with-file');
      });
    }
  });
};
