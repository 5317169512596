var A24, BannerCookie;

BannerCookie = require('cookies-js');

A24 = require('data/constants');

module.exports = function() {
  var $header, dismiss_banner;
  $header = $('header');
  dismiss_banner = function(time) {
    return BannerCookie.set('cookie-banner-dismissed', true, {
      expires: time
    });
  };
  return $('aside.cookie-banner').each(function(index, el) {
    var banner, close;
    banner = $(el);
    close = banner.find('button.close-cookie-banner');
    if (!BannerCookie('seent-it')) {
      BannerCookie.set('seent-it', true, {
        expires: A24.ONE_WEEK
      });
      banner.addClass('active');
    }
    return close.on('click', function(e) {
      banner.removeClass('active');
      if (!BannerCookie.get('cookie-banner-dismissed')) {
        return dismiss_banner(A24.FUTURE);
      }
    });
  });
};
