var $, ImageLoad;

$ = require('jquery');

ImageLoad = require('util/image-load');

module.exports = function() {
  return $('section.header-image img').each(function(index, el) {
    return new ImageLoad($(el), function() {});
  });
};
